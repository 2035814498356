import {getBannersData, getLayoutData, getPageData} from "@/pages/api/api";
import {SEO_QUERY} from "@/pages/api/fragments";
import React from "react";
import dynamic from "next/dynamic";
import Loading from "@/components/common/Loading";
import {PAGES} from "@/constants";

const ErrorPageComponent = dynamic(() => import('@/components/errorPageComponent/ErrorPageComponent'), {
	loading: () => <Loading />,
	ssr: false
})

export default function ErrorPage({ data, globals, locale }) {
	return (
		<>
			<ErrorPageComponent globals={globals}/>
		</>
	);
}

const ERROR_PAGE_QUERY = `
	query GetHomePageData($locale: SiteLocale) {
		allSinglePages(locale: $locale, filter: {slug: {eq: "404"}}) {
			id
			singlePageName
			slug
			${SEO_QUERY}
		}
	}
`;


export async function getStaticProps(context) {
	const locale = context?.locale || 'pl';
	const data = await getPageData('allSinglePages', PAGES.HOME, ERROR_PAGE_QUERY, locale);
	const banners = await getBannersData(locale)
	const layout = await getLayoutData(locale)

	return {
		props: {
			data,
			banners,
			locale,
			...layout,
			isDarkSearch: false,
			isHomePage: true,
		},
		revalidate: 60 * 60 * 12
	};
}
